import {
  Button,
  Center,
  Link,
  Text,
  useBoolean,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { BackButton } from "donation-form/src/components/form/shared/BackButton";
import { DonationRuleReview } from "donation-form/src/components/form/shared/DonationRuleReview";
import { PaymentFormError } from "donation-form/src/components/form/shared/PaymentFormError";
import {
  FormStep,
  PlaidDetailsRequest,
  useFormControl,
} from "donation-form/src/components/form/useFormControl";
import {
  createPledge,
  PledgeArgs,
  PledgeCreateResponse,
} from "donation-form/src/utils/api-create-pledge";
import { tracker } from "donation-form/src/utils/tracker";
import * as React from "react";
import { useContext, useState } from "react";
import Reaptcha from "reaptcha";
import { DonationFormConfigContext } from "donation-form/src/components/FormContextProvider";

export function Step4Review() {
  const theme = useTheme();
  const form = useFormControl();
  const formConfig = useContext(DonationFormConfigContext);

  const [recaptchaV2Token, setRecaptchaV2Token] = useState<string | null>(null);

  const [isShowMessageCaptchaV2, setIsShowMessageCaptchaV2] = useBoolean(true);
  const [isRecaptchaV3Failed, setIsRecaptchaV3Failed] = useBoolean();
  const [isUnknownError, setIsUnknownError] = useBoolean();
  const [isLoading, setIsLoading] = useBoolean();

  const handleSubmit = async () => {
    setIsLoading.on();
    setIsUnknownError.off();

    const createPledgeArgs: Partial<PledgeArgs> = {
      formConfig,
      form,
      isRecaptchaV3Failed,
      recaptchaV2Token,
      userData: form.userData!,
    };

    createPledgeArgs.plaidDetailsRequest = form.plaidDetailsRequest as PlaidDetailsRequest;

    if (form.stripeToken) {
      createPledgeArgs.stripeToken = form.stripeToken;
    }
    const response = await createPledge(createPledgeArgs as PledgeArgs);

    setIsLoading.off();

    switch (response) {
      case PledgeCreateResponse.Success: {
        const ReviewStepEnumOld = 3;
        tracker.checkoutStepSubmitted({
          step: ReviewStepEnumOld,
          form,
          config: formConfig,
        });
        return form.setFormStep(FormStep.Step5Success);
      }
      case PledgeCreateResponse.ErrorCardInvalid: {
        // because https://sentry.io/organizations/givemomentum/issues/3261401107/activity/
        form.setStripeToken(null);
        form.setStripeCardInfo(null);
        form.isCardValidationError.set(true);
        form.setFormStep(FormStep.Step2PaymentConfig);
        return;
      }
      case PledgeCreateResponse.ErrorCaptchaV3:
      case PledgeCreateResponse.ErrorCaptchaV2:
        return setIsRecaptchaV3Failed.on();
      case PledgeCreateResponse.ErrorUnknown:
      default:
        return setIsUnknownError.on();
    }
  };

  return (
    <>
      <BackButton
        onClick={() => form.setFormStep(FormStep.Step3UserInfo)}
        isDisabled={isLoading}
      />

      <DonationRuleReview isShowTip={formConfig.is_enable_tip} />

      <Center>
        <Text mt={theme.space.lg} color="gray.400">
          By continuing, you agree to Momentum's{" "}
          <Link
            href="https://givemomentum.com/terms-of-use.html"
            isExternal
            textDecoration="underline"
          >
            Terms
          </Link>{" "}
          &{" "}
          <Link
            href="https://givemomentum.com/privacy-policy.html"
            isExternal
            textDecoration="underline"
          >
            Privacy Policy
          </Link>
        </Text>
      </Center>

      {formConfig.is_US_political_campaign_mode && (
        <Text mt={theme.space.md} color="gray.400">
          Contributions to {formConfig.charity.name} are not deductible as charitable
          contributions for federal income tax purposes. By making a contribution, I confirm
          the following: I am a U.S. citizen or lawfully admitted permanent resident (i.e.,
          green card holder). This contribution is made from my own funds, and funds are not
          being provided to me by another person or entity for the purpose of making this
          contribution. I am making this contribution with my own personal credit card and
          not with a corporate or business credit card or a card issued to another person. I
          am at least eighteen years old. I am not a federal contractor.
        </Text>
      )}

      <Center mt={theme.space.lg}>
        <Button
          onClick={async () => {
            const isV3FailedAndUserAlreadySawV2Error = isRecaptchaV3Failed;
            if (isV3FailedAndUserAlreadySawV2Error) {
              setIsShowMessageCaptchaV2.off();
            }
            await handleSubmit();
          }}
          isLoading={isLoading}
          variant="solid"
          size="lg"
        >
          Donate
        </Button>
      </Center>

      {isRecaptchaV3Failed && (
        <Center mt={theme.space.lg}>
          <VStack>
            <PaymentFormError isVisible={isShowMessageCaptchaV2}>
              Please solve the captcha and try again
            </PaymentFormError>
            <Reaptcha
              sitekey={process.env.NEXT_PUBLIC_CAPTCHA_V2_PUBLIC_KEY!}
              onVerify={token => setRecaptchaV2Token(token)}
            />
          </VStack>
        </Center>
      )}

      <PaymentFormError isVisible={isUnknownError}>
        An error occurred, please try again or contact support at support@givemomentum.com
      </PaymentFormError>
    </>
  );
}

import {
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Tr,
  useTheme,
} from "@chakra-ui/react";
import { useFormControl } from "donation-form/src/components/form/useFormControl";
import { useFormConfig } from "donation-form/src/components/FormContextProvider";

export function DonationRuleReview(props: {
  title?: string;
  isShowTip?: boolean;
  isTitleCentered?: boolean;
  textStyle?: string;
}) {
  const theme = useTheme();
  const formConfig = useFormConfig();
  const form = useFormControl();

  const ruleEarmarkingLabel = formConfig.earmarkings.find(
    earmarking => earmarking.id === form.earmarking,
  )?.label;

  const isMultiRowSummary = true;
  return (
    <>
      <Text
        as="h3"
        mt={theme.space.md}
        textStyle={props.textStyle ?? "h2"}
        textAlign={props.isTitleCentered ? "center" : "left"}
      >
        {props.title ?? "Review"}
      </Text>

      <Table
        variant="minimalistic"
        border={isMultiRowSummary ? "2px solid" : "3px solid"}
        borderLeft={isMultiRowSummary ? "3px solid" : "4px solid"}
        borderRight={isMultiRowSummary ? "3px solid" : "4px solid"}
      >
        <Tbody w="100%">
          <Tr>
            <Td verticalAlign="top">Donation</Td>
            <Td isNumeric textTransform="lowercase">
              {!form.isRoundUpBasedRule() && (
                <>
                  {form.isPercentBasedRule()
                    ? `${form.amount!}%`
                    : `$${form.amount!.toFixed(2)}`}{" "}
                  {form.isSpendingRule() && "per purchase"}
                  {form.isPersonalRule() && !form.isSpendingRule() && "recurring"}
                  {form.formRule.rule.kind === "once" && "one time"}
                  {form.formRule.rule.kind === "monthly" && "monthly"}
                  {formConfig.is_org_pardes &&
                    (form.formRule.rule.title.includes("over")
                      ? `every month ${form.formRule.rule.title
                        .replace("Pay ", "")
                        .replace("over", "for")}`
                      : "")}
                </>
              )}
              {form.isRoundUpBasedRule() && "round up to the nearest dollar"}
            </Td>
          </Tr>
          {form.isPersonalRule() && (
            <Tr>
              <Td pt={0}>
                <Text fontSize="0.85rem" color="gray.500">
                  when {form.formRule.rule.trigger_lower}
                </Text>
              </Td>
              <Td />
            </Tr>
          )}
          {props.isShowTip && (
            <Tr>
              <Td>Tip</Td>
              <Td isNumeric>{form.tip}%</Td>
            </Tr>
          )}
          {form.isCoveringFees.val && (
            <Tr>
              <Td>Covering {form.feesCoveringPercentage.val}% fee</Td>
              <Td isNumeric>
                ${(form.amount! * (form.feesCoveringPercentage.val! / 100)).toFixed(2)}
              </Td>
            </Tr>
          )}
          {form.isDedicateDonation.val && (
            <Tr>
              <Td>
                {form.donationDedicationType.val === "in_honor_of" && "In honor of"}
                {form.donationDedicationType.val === "in_memory_of" && "In memory of"}
              </Td>
              <Td isNumeric>{form.donationDedicatee.val}</Td>
            </Tr>
          )}
          {form.donationOnBehalfOf.val && (
            <Tr>
              <Td>On behalf of</Td>
              <Td isNumeric>{form.donationOnBehalfOf.val}</Td>
            </Tr>
          )}
          {ruleEarmarkingLabel && (
            <Tr>
              <Td>Program</Td>
              <Td isNumeric>{ruleEarmarkingLabel}</Td>
            </Tr>
          )}
          {form.isPersonalRule() &&
            (form.limit ? (
              <Tr>
                <Td>Monthly limit</Td>
                <Td isNumeric>${form.limit}</Td>
              </Tr>
            ) : (
              <Tr>
                <Td>Monthly limit</Td>
                <Td isNumeric>none</Td>
              </Tr>
            ))}
          <Tr>
            {form.isPlaidPay() && (
              <>
                <Td>Bank Account</Td>
                <Td isNumeric>
                  ****{" "}
                  {
                    form.plaidAccountsAvailable!.find(a => a.id === form.plaidAccountId)!
                      .mask
                  }
                </Td>
              </>
            )}
            {form.isStripePay() && (
              <>
                <Td>Card</Td>
                <Td isNumeric>
                  {form.stripeCardInfo?.brand} **** {form.stripeCardInfo?.last4}
                </Td>
              </>
            )}
          </Tr>
          {form?.userData?.comment && (
            <Tr>
              <Td>{formConfig.comment_field_label ?? "Comment"}</Td>
              <Td isNumeric>{form.userData.comment}</Td>
            </Tr>
          )}
        </Tbody>

        {form.formRule.rule.kind !== "once" && (
          <TableCaption>Withdrawn on the 1st of each month</TableCaption>
        )}
      </Table>
    </>
  );
}

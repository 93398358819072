import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  Flex,
  Text,
  useBreakpointValue,
  useTheme,
} from "@chakra-ui/react";
import { Theme } from "@chakra-ui/theme";
import * as React from "react";

import { useContext, useEffect } from "react";

import Earmarking from "donation-form/src/components/form/Step1Rule/Earmarking";
import { ProgressBar } from "donation-form/src/components/form/Step1Rule/ProgressBar";

import { RulePane } from "donation-form/src/components/form/Step1Rule/RulePane";
import {
  FormStep,
  PaymentMethod,
  useFormControl,
} from "donation-form/src/components/form/useFormControl";
import { FormRule, getTabTitle } from "donation-form/src/utils/api-cms";
import { useIsWidgetMode } from "donation-form/src/components/form/useIsWidgetMode";
import { styles } from "donation-form/src/components/form/Step1Rule/styles";
import { DonationFormConfigContext } from "donation-form/src/components/FormContextProvider";

export function Step1Rule() {
  const theme: Theme | any = useTheme();

  const configCtx = useContext(DonationFormConfigContext);
  const form = useFormControl();
  const isWidgetMode = useIsWidgetMode();

  const rulesEnabled = configCtx.rules.filter(rule => rule.is_enabled);

  useEffect(() => {
    if (form.isSpendingRule()) {
      form.setPaymentMethod(PaymentMethod.Plaid);
    }
  }, []);

  const buttonSizeArray = ["md", "lg"];
  const buttonSize = useBreakpointValue(buttonSizeArray) ?? buttonSizeArray[1];

  // show the description of the non-selected index, only usable with two active rules
  let anotherRule = rulesEnabled.find(rule => rule.rule.id !== form.formRule.rule.id)!;
  if (!anotherRule) {
    /* eslint-disable prefer-destructuring */
    anotherRule = rulesEnabled[1];
  }

  const isUsingTabsForRuleSwitching =
    configCtx.is_using_tabs_for_rule_switching && rulesEnabled.length > 1;

  const isTitleVisibleInRuleForm = !isWidgetMode && configCtx.title;

  return (
    <>
      {isTitleVisibleInRuleForm && (
        <Text as="h1" textStyle="h1" mt={0}>
          {configCtx.title}
        </Text>
      )}

      {configCtx.is_progress_bar_enabled && (
        <ProgressBar
          raisedNum={configCtx.progress_bar_raised}
          raisedLabelPostfix={configCtx.progress_bar_label_start_postfix}
          raisedOffset={configCtx.progress_bar_offset}
          target={configCtx.progress_bar_target}
          mt={
            (isTitleVisibleInRuleForm || configCtx.is_progress_bar_enabled) && !isWidgetMode ? theme.space.lg : 0
          }
        />
      )}

      {configCtx.description && (
        <Box
          mt={
            (isTitleVisibleInRuleForm || configCtx.is_progress_bar_enabled) && theme.space.md
          }
          dangerouslySetInnerHTML={{ __html: configCtx.description }}
        />
      )}

      {isUsingTabsForRuleSwitching && (
        <ButtonGroup
          size={buttonSize}
          isAttached
          variant="outline"
          w="100%"
          mt={(configCtx.title || configCtx.description) && theme.space.md}
          colorScheme="gray"
        >
          {rulesEnabled.map((formRule: FormRule) => (
            <Button
              key={formRule.id}
              onClick={() => {
                form.setFormRule(formRule);
                if (formRule.rule.type === "SPENDING") {
                  form.setPaymentMethod(PaymentMethod.Plaid);
                } else {
                  form.setPaymentMethod(PaymentMethod.CreditCard);
                }
              }}
              isActive={formRule === form.formRule}
              w="100%"
              h={12}
              _last={{ borderLeftWidth: 0 }}
              _first={{ borderRightWidth: 0 }}
              textTransform={configCtx.is_org_pardes ? "inherit" : "capitalize"}
              fontSize={styles.fontSize}
            >
              {getTabTitle(formRule).replace(" months", "m")}
            </Button>
          ))}
        </ButtonGroup>
      )}

      <Box mt={isUsingTabsForRuleSwitching ? theme.space.md : 0} minH={[null, null, "58px"]}>
        {rulesEnabled.map(formRule => (
          <RulePane key={formRule.id} formRule={formRule} />
        ))}
      </Box>

      <Earmarking />

      {configCtx.is_enable_fees_covering_field && (
        <Checkbox
          isChecked={form.isCoveringFees.val}
          onChange={e => form.isCoveringFees.set(e.target.checked)}
          mt={4}
        >
          I'd like to help cover the processing fees of {form.feesCoveringPercentage.val}% for
          my donation.
        </Checkbox>
      )}

      <Center mt={theme.space.md}>
        <Flex direction="column">
          <Button
            disabled={!form.amount}
            onClick={() => form.setFormStep(FormStep.Step2PaymentConfig)}
            variant="solid"
            size="lg"
          >
            Next
          </Button>

          {!isUsingTabsForRuleSwitching && rulesEnabled.length === 2 && (
            <Button
              variant="link"
              mt={theme.space.md - 1.5}
              onClick={() => form.setFormRule(anotherRule)}
            >
              {anotherRule.switch_CTA_label}
            </Button>
          )}
        </Flex>
      </Center>
    </>
  );
}

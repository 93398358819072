import {
  Box,
  Center,
  ChakraProvider,
  Container,
  Flex,
  HStack,
  Image,
  Link,
  Spacer,
  Text,
  useTheme,
} from "@chakra-ui/react";
import "focus-visible/dist/focus-visible";
import "iframe-resizer/js/iframeResizer.contentWindow.min";
import { Global } from "@emotion/react";

import { GoogleAnalyticsSnippet } from "donation-form/src/components/form/shared/GoogleAnalyticsSnippet";
import { MomentumLogo } from "donation-form/src/components/form/shared/MomentumLogo";
import { SegmentSnippet } from "donation-form/src/components/form/shared/SegmentSnippet";
import { FormControlContextProvider } from "donation-form/src/components/form/useFormControl";
import { DonationFormConfig } from "donation-form/src/utils/api-cms";
import Head from "next/head";
import { createContext, useContext } from "react";
import { useThemeStylesConfigured } from "donation-form/src/styles/useThemeStylesConfigured";

export const DonationFormConfigContext = createContext<DonationFormConfig>(
  {} as any,
);
export const tipDefault = 15;
export const limitDefault = 50;
export const useFormConfig = () => useContext(DonationFormConfigContext);

export function FormContextProvider(props: {
  formConfig: DonationFormConfig;
  children: React.ReactNode;
  styles: ReturnType<typeof useThemeStylesConfigured>;
}) {
  const { styles } = props;

  const config = styles.configUpdated;

  const comp = {
    isShowHeadMetadata: !config.is_preview_mode,
    isRenderAnalytics: !config.is_preview_mode,
    isRenderHeader: !styles.isWidgetMode && !config.is_embedded_mode,
    isRenderTitle: !styles.isWidgetMode && config.is_embedded_mode,
    isShowFooter: !styles.isWidgetMode && !config.is_hide_footer,
    isFullHeight: !styles.isWidgetMode,
  };

  return (
    <DonationFormConfigContext.Provider value={config}>
      <ChakraProvider theme={styles.theme}>
        {styles.fontUrl && (
          <Global
            styles={`
              @import url("${styles.fontUrl}");
            `}
          />
        )}
        {styles.customCssUrl && (
          <Global
            styles={`
              @import url("${styles.customCssUrl}");
            `}
          />
        )}

        {comp.isShowHeadMetadata && (
          <Head>
            <title>
              {config.seo_title
                ? config.seo_title
                : `${config.title} - ${config.charity.name}`}
            </title>
            <meta
              name="description"
              content={config.seo_description ?? "Donate now"}
            />
            <link rel="icon" href={config.favicon} />
          </Head>
        )}

        {comp.isRenderAnalytics && (
          <Head>
            <SegmentSnippet trackerId={config.segment_id} />
            <GoogleAnalyticsSnippet
              trackerId={config.google_analytics_id}
            />
          </Head>
        )}

        <Head>
          <meta httpEquiv="Access-Control-Allow-Origin" content="*" />
        </Head>

        <Flex minH={comp.isShowFooter ? "100vh" : "100%"} direction="column">
          {comp.isRenderHeader && <Header />}
          {comp.isRenderTitle && (
            <Center>
              <Text as="h1" textStyle="h1" mt={styles.theme.space.xl}>
                {config.title}
              </Text>
            </Center>
          )}

          <Box
            my={
              styles.isShowContainer
                ? [
                  styles.isWidgetMode ? styles.theme.space.xl : 0,
                  null,
                  styles.theme.space.xl2,
                ]
                : 0
            }
            mt={
              styles.isShowContainer
                ? [styles.theme.space.xl, null, styles.theme.space.xl2]
                : 0
            }
          >
            <Container
              maxW={styles.isShowContainer ? "container.lg" : "100%"}
              px={styles.isShowContainer ? styles.theme.space.md : 0}
            >
              <Box
                p={
                  styles.isShowContainer
                    ? [styles.theme.space.xs, null, styles.theme.space.xl]
                    : 0
                }
                bg={styles.formBgColor}
                borderRadius={styles.isShowContainer ? 5 : 0}
              >
                <FormControlContextProvider config={config}>
                  {props.children}
                </FormControlContextProvider>

                {styles.isShowPoweredBy && styles.isWidgetMode && (
                  <Center mt={styles.theme.space.lg}>
                    <PoweredByMomentum isSmallSize />
                  </Center>
                )}
              </Box>
            </Container>
          </Box>

          {comp.isShowFooter && (
            <>
              <Spacer />
              <Footer />
            </>
          )}
        </Flex>
      </ChakraProvider>
    </DonationFormConfigContext.Provider>
  );
}

function Header() {
  const theme = useTheme();
  const configCtx = useFormConfig();

  let headerLogoUrl = "";
  if (
    configCtx.header_logo_url.includes("http://") ||
    configCtx.header_logo_url.includes("https://")
  ) {
    headerLogoUrl = configCtx.header_logo_url;
  } else {
    headerLogoUrl = `https://${configCtx.header_logo_url}`;
  }

  return (
    <Box
      p={theme.space.md}
      bg={
        configCtx.is_dark_mode_enabled_for_header_and_footer
          ? theme.colors.brand.dark
          : "white"
      }
      justifyContent="center"
      d="flex"
      pos="relative"
    >
      <a href={headerLogoUrl}>
        <Image
          src={configCtx.header_logo}
          alt={configCtx.header_logo}
          width={configCtx.header_logo_width}
          objectFit="cover"
          fallback={<Box height={50} width={configCtx.header_logo_width} />}
        />
      </a>
    </Box>
  );
}

function Footer() {
  const theme = useTheme();
  const configCtx = useContext(DonationFormConfigContext);
  return (
    <Box
      bg={
        configCtx.is_dark_mode_enabled_for_header_and_footer
          ? theme.colors.brand.dark
          : "white"
      }
      p={theme.space.xl}
      mt={[theme.space.xl, theme.space.xl, 0]}
    >
      <Flex direction="column" align="center">
        <PoweredByMomentum />

        <Text
          mt={theme.space.md}
          fontSize="xs"
          color="gray.400"
          textAlign="center"
        >
          Protected by reCAPTCHA,{" "}
          <Link href="https://policies.google.com/privacy" isExternal>
            Privacy
          </Link>{" "}
          and{" "}
          <Link href="https://policies.google.com/terms" isExternal>
            Terms
          </Link>{" "}
          apply
        </Text>
      </Flex>
    </Box>
  );
}

function PoweredByMomentum(props: { isSmallSize?: boolean }) {
  const theme = useTheme();
  const configCtx = useContext(DonationFormConfigContext);

  return (
    <Link
      isExternal
      href="https://givemomentum.com/"
      _hover={{ textDecoration: "none" }}
    >
      <HStack spacing={0}>
        <Text
          mr={theme.space.sm}
          fontSize={props.isSmallSize ? "0.78rem" : "0.93rem"}
          mt={props.isSmallSize ? "0px" : "2px"}
          fontWeight="bold"
          color={
            configCtx.is_dark_mode_enabled_for_header_and_footer
              ? "white"
              : "rgb(43, 48, 112)"
          }
        >
          Powered by
        </Text>
        <MomentumLogo
          width={props.isSmallSize ? 120 : 128}
          height={props.isSmallSize ? 21 : 25}
          color={
            configCtx.is_dark_mode_enabled_for_header_and_footer
              ? "white"
              : null
          }
        />
      </HStack>
    </Link>
  );
}

import { Theme } from "@chakra-ui/theme";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useIsWidgetMode } from "donation-form/src/components/form/useIsWidgetMode";
import { createThemeWithColorsAndBgs } from "donation-form/src/styles/theme";
import { DonationFormConfig } from "donation-form/src/utils/api-cms";

export function useThemeStylesConfigured(props: {
  formConfig: DonationFormConfig;
  theme?: Theme | any;
  isShowPoweredBy?: boolean;
  isShowContainer?: boolean;
}) {
  const isWidgetMode = useIsWidgetMode();
  const router = useRouter();
  const [theme, setTheme] = useState(
    props.theme ?? createThemeWithColorsAndBgs(props.formConfig),
  );
  const [formBgColor, setFormBgColor] = useState("white");
  const [fontUrl, setFontUrl] = useState<string | undefined>(
    props.formConfig.font_url,
  );
  const [customCssUrl, setCustomCssUrl] = useState<string | null>(null);
  const [isShowContainer, setIsShowContainer] = useState(props.isShowContainer ?? true);
  const [configUpdated, setConfigUpdated] = useState(props.formConfig);

  useEffect(() => {
    const queryParams = router.query as any as NodeJS.Dict<string>;
    if (Object.keys(router.query).length === 0) {
      setTheme(props.theme ?? createThemeWithColorsAndBgs(props.formConfig));
    } else {
      const configUpdated = { ...props.formConfig };
      configUpdated.background_color =
        queryParams["background-color"] ?? configUpdated.background_color;
      configUpdated.primary_color =
        queryParams["primary-color"] ?? configUpdated.primary_color;
      configUpdated.font_url = queryParams["font-url"] ?? configUpdated.font_url;
      configUpdated.font_family =
        queryParams["font-family"] ?? configUpdated.font_family;
      configUpdated.is_progress_bar_enabled = (
        getQueryParamBoolean(
          queryParams,
          "show-progress-bar",
          { default: props.formConfig.is_progress_bar_enabled },
        )
      );
      setConfigUpdated(configUpdated);
      setFontUrl(configUpdated.font_url);
      setFormBgColor(queryParams["form-background-color"] ?? formBgColor);
      setCustomCssUrl(queryParams["custom-css-url"] ?? null);
      setIsShowContainer(getQueryParamBoolean(queryParams, "show-container"));

      const themeNew = createThemeWithColorsAndBgs(configUpdated);
      setTheme(themeNew);
    }
  }, [props.theme, router.query]);

  return {
    theme,
    isWidgetMode,
    formBgColor,
    fontUrl,
    customCssUrl,
    isShowContainer,
    configUpdated,
    isShowPoweredBy: props.isShowPoweredBy ?? true,
  };
}

function getQueryParamBoolean(queryParams: NodeJS.Dict<string>, key: string, options?: {
  default?: boolean
}): boolean {
  if (!queryParams[key]) {
    return options?.default ?? false;
  }
  const value = queryParams[key];
  return value === "true";
}

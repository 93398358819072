import { Checkbox, Select, useTheme, Flex } from "@chakra-ui/react";
import { Theme } from "@chakra-ui/theme";

import { useContext } from "react";

import { FormControlContext } from "donation-form/src/components/form/useFormControl";
import { DonationFormConfigContext } from "donation-form/src/components/FormContextProvider";

export default function Earmarking() {
  const configCtx = useContext(DonationFormConfigContext);
  const ruleCtx = useContext(FormControlContext);
  const theme: Theme | any = useTheme();

  if (configCtx.earmarkings?.length && configCtx.is_earmarkings_visible) {
    return (
      <Flex
        direction={["column", null, "row"]}
        mt={ruleCtx.isPersonalRule() ? theme.space.sm : theme.space.md}
        gap={theme.space.md}
      >
        <Checkbox
          isChecked={Boolean(ruleCtx.earmarking)}
          onChange={event => {
            if (event.target.checked) {
              ruleCtx.setEarmarking(configCtx.earmarkings[0].id!);
            } else {
              ruleCtx.setEarmarking(null);
            }
          }}
        >
          Select a specific program
        </Checkbox>

        <Select
          value={(ruleCtx.earmarking as any) ?? ""}
          visibility={ruleCtx.earmarking ? "visible" : "hidden"}
          onChange={event => {
            const earmarkingNew = configCtx.earmarkings.find(
              earmarking => earmarking.id! === Number(event.target.value),
            )!;
            ruleCtx.setEarmarking(earmarkingNew.id!);
          }}
          w="fit-content"
          maxW="69%"
        >
          {configCtx.earmarkings
            .filter(earmarking => !earmarking.is_archived && earmarking.label)
            .map(earmarking => (
              <option value={earmarking.id} key={earmarking.uuid}>
                {earmarking.label}
              </option>
            ))}
        </Select>
      </Flex>
    );
  }
  return null;
}

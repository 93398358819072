import { InfoOutlineIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  Flex,
  HStack,
  Icon,
  InputGroup,
  InputRightElement,
  Tooltip,
  useTheme,
} from "@chakra-ui/react";
import { FormikProps } from "formik";
import { InputControl, SelectControl } from "formik-chakra-ui";
import * as React from "react";
import { useContext } from "react";
import { FlushedInputControl } from "donation-form/src/components/form/shared/FlushedInputControl";
import { useAddressInputs } from "donation-form/src/components/form/Step3UserInfo/useAddressInputs";
import { Tip } from "donation-form/src/components/form/Step3UserInfo/Tip";
import { useFormControl } from "donation-form/src/components/form/useFormControl";
import { DonationFormConfigContext } from "donation-form/src/components/FormContextProvider";
import * as Yup from "yup";

export function useUserInfoForm() {
  const formConfig = useContext(DonationFormConfigContext);
  const form = useFormControl();
  const theme = useTheme();

  const hooks = {
    addressInputs: useAddressInputs({
      isShowPoliticalTooltip: formConfig.is_US_political_campaign_mode,
      isOnlyUS: formConfig.is_US_political_campaign_mode,
      isRequired: formConfig.is_US_political_campaign_mode || formConfig.is_address_required,
      addressDataInitial: form.userData,
    }),
  };

  return {
    getInitialValues: () => ({
      salutation: form.userData?.salutation ?? "",
      email: form.userData?.email ?? "",
      firstName: form.userData?.firstName ?? "",
      lastName: form.userData?.lastName ?? "",
      employer: form.userData?.employer ?? "",
      occupation: form.userData?.occupation ?? "",
      phone: form.userData?.phone ?? "",
      comment: form.userData?.comment ?? "",

      ...hooks.addressInputs.getInitialValues(),
    }),
    getValidationSchema: () => ({
      email: Yup.string().email("Enter a valid email").required(),
      firstName: Yup.string().required("Field is required"),
      lastName: Yup.string().required("Field is required"),
      employer: formConfig.is_US_political_campaign_mode
        ? Yup.string().required("Field is required")
        : Yup.string(),
      occupation: formConfig.is_US_political_campaign_mode
        ? Yup.string().required("Field is required")
        : Yup.string(),
      ...hooks.addressInputs.getValidationSchema(),
    }),
    saveFormData: (data: any) => {
      form.setUserData(data);
    },
    render: (formik: FormikProps<any>) => (
      <Flex direction="column">
        <HStack spacing={theme.space.md} mt={theme.space.md} alignItems="end">
          {formConfig.is_enable_salutation_field && (
            <SelectControl name="salutation" h="fit-content">
              <option value="Ms.">Ms.</option>
              <option value="Mrs.">Mrs.</option>
              <option value="Mr.">Mr.</option>
              <option value="Miss.">Miss.</option>
              <option value="Dr.">Dr.</option>
              <option value="Mx.">Mx.</option>
              <option value="Prof.">Prof.</option>
              <option value="Rev.">Rev.</option>
            </SelectControl>
          )}

          <FlushedInputControl name="firstName" />
          <FlushedInputControl name="lastName" />
        </HStack>

        {formConfig.is_phone_number_field_visible ? (
          <HStack spacing={theme.space.md} mt={theme.space.md} alignItems="top">
            <FlushedInputControl name="email" />
            <FlushedInputControl
              name="phone"
              placeholder="Phone"
              inputProps={{ type: "tel", autocomplete: "tel" }}
            />
          </HStack>
        ) : (
          <FlushedInputControl name="email" mt={theme.space.md} />
        )}

        {(formConfig.is_address_required || formConfig.is_US_political_campaign_mode) && (
          hooks.addressInputs.render(formik)
        )}

        {formConfig.is_US_political_campaign_mode && (
          <>
            <Box mt={theme.space.md} px={theme.space.sm}>
              This information is required by federal campaign financing law and will not
              be shared with any third parties.
              <Tooltip
                hasArrow
                label="Federal law requires us to use our best efforts to collect and report the name, mailing address, occupation and name of employer of individuals whose contributions aggregate in excess of $200 in a calendar year."
                closeOnClick={false}
              >
                <InfoOutlineIcon ml={theme.space.sm} mb="1px" color="brand.200" />
              </Tooltip>
            </Box>

            <HStack spacing={theme.space.md} mt={theme.space.md} alignItems="top">
              <FlushedInputControl name="employer" />
              <FlushedInputControl name="occupation" />
            </HStack>
          </>
        )}

        {formConfig.is_enable_comment_field && !formConfig.comment_field_label && (
          <InputGroup mt={theme.space.lg}>
            <InputControl
              name="comment"
              w="100%"
              inputProps={{
                placeholder: "Your reason to donate (optional)",
                variant: "flushed",
              }}
            />
            <InputRightElement>
              <Tooltip
                hasArrow
                label='Publicly displayed on the "Recent donations" & "Leaderboard"'
                closeOnClick={false}
              >
                <InfoOutlineIcon mt={theme.space.sm} color="brand.200" />
              </Tooltip>
            </InputRightElement>
          </InputGroup>
        )}
        {formConfig.is_enable_comment_field && formConfig.comment_field_label && (
          <InputGroup mt={theme.space.lg}>
            <InputControl
              name="comment"
              w="100%"
              inputProps={{
                placeholder: formConfig.comment_field_label,
                variant: "flushed",
              }}
            />
          </InputGroup>
        )}

        <Flex gap={3} direction="column">
          {formConfig.is_enable_newsletter_subscription_field && (
            <Checkbox
              isChecked={form.isEmailSubscribe.val}
              onChange={e => form.isEmailSubscribe.set(e.target.checked)}
              mt={theme.space.md}
            >
              Subscribe to our newsletter
            </Checkbox>
          )}

          {formConfig.is_enable_anonymous_donation_field && (
            <Checkbox
              isChecked={form.isDonationNonPublic.val}
              onChange={e => form.isDonationNonPublic.set(e.target.checked)}
            >
              <Tooltip
                label={`This will hide your name and donor information from all public acknowledgment; ${formConfig.charity.name} will still receive your information.`}
                placement="bottom"
              >
                <Flex align="center">
                  Make my donation anonymous
                  <Icon
                    ml={theme.space.sm}
                    mt="2px"
                    w="15px"
                    h="15px"
                    as={QuestionOutlineIcon}
                    color="gray.500"
                  />
                </Flex>
              </Tooltip>
            </Checkbox>
          )}
        </Flex>

        {formConfig.is_enable_tip && <Tip />}
      </Flex>
    ),
  };
}
